var render = function render(){var _vm=this,_c=_vm._self._c;return _c('td',{style:({
    'background-color':
      _vm.context == 'evaluation' && !_vm.allows_crud
        ? _vm.gradeRangeBackground(
            _vm.getStudentScore.final_grade != null
              ? parseFloat(_vm.getStudentScore.final_grade.toFixed(2))
              : _vm.getStudentScore.manual_grade != null
              ? parseFloat(_vm.getStudentScore.manual_grade.toFixed(2))
              : _vm.getStudentScore.automated_grade != null
              ? parseFloat(_vm.getStudentScore.automated_grade.toFixed(2))
              : _vm.institution.min_grade
          ) + ' !important'
        : '',
  })},[(_vm.context == 'test')?_c('div',[(!_vm.allows_crud)?_c('div',{staticClass:"d-flex"},[(
          _vm.getStudentScore.start_time == null &&
          _vm.getStudentScore.manual_score == null &&
          _vm.getStudentScore.final_score == null &&
          _vm.getStudentScore.automated_score == 0
        )?_c('div',{staticClass:"w-100"},[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive.hover.right",value:(
            'Instrumento no contestado'
          ),expression:"\n            'Instrumento no contestado'\n          ",modifiers:{"v-secondary":true,"noninteractive":true,"hover":true,"right":true}}]},[_vm._v("- ")])]):_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive.hover",value:(
          'Puntaje obtenido: ' +
          +(
            Math.round(
              Number(
                _vm.getStudentScore.manual_score == null
                  ? _vm.getStudentScore.automated_score
                  : _vm.getStudentScore.manual_score
              ) + 'e+2'
            ) + 'e-2'
          ) +
          '/' +
          _vm.test.max_score
        ),expression:"\n          'Puntaje obtenido: ' +\n          +(\n            Math.round(\n              Number(\n                getStudentScore.manual_score == null\n                  ? getStudentScore.automated_score\n                  : getStudentScore.manual_score\n              ) + 'e+2'\n            ) + 'e-2'\n          ) +\n          '/' +\n          test.max_score\n        ",modifiers:{"v-secondary":true,"noninteractive":true,"hover":true}}],staticClass:"w-100"},[_vm._v(" "+_vm._s(_vm.getStudentScore.final_score != null ? parseFloat(_vm.getStudentScore.final_score.toFixed(2)) : _vm.getStudentScore.manual_score != null ? parseFloat(_vm.getStudentScore.manual_score.toFixed(2)) : _vm.getStudentScore.automated_score != null ? parseFloat(_vm.getStudentScore.automated_score.toFixed(2)) : 0)+" ")]),(
          _vm.getStudentScore.final_score == null &&
          _vm.getStudentScore.manual_score == null &&
          _vm.getStudentScore.automated_score != null
        )?_c('b-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive.hover",value:('Puntaje automático'),expression:"'Puntaje automático'",modifiers:{"v-secondary":true,"noninteractive":true,"hover":true}}],staticClass:"ml-1 mt-1",attrs:{"icon":"lightbulb"}}):_vm._e(),(_vm.getStudentScore.start_time == null)?_c('b-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive.hover.right",value:(
          'Instrumento no contestado.'
        ),expression:"\n          'Instrumento no contestado.'\n        ",modifiers:{"v-secondary":true,"noninteractive":true,"hover":true,"right":true}}],staticClass:"ml-1 mt-1",attrs:{"icon":"journal-x"}}):_c('b-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive.hover.right",value:(
          'El instrumento se inicio el ' +
          _vm.$moment(_vm.getStudentScore.start_time).format('DD/MM/YYYY HH:mm')
        ),expression:"\n          'El instrumento se inicio el ' +\n          $moment(getStudentScore.start_time).format('DD/MM/YYYY HH:mm')\n        ",modifiers:{"v-secondary":true,"noninteractive":true,"hover":true,"right":true}}],staticClass:"ml-1 mt-1",attrs:{"icon":"journal-check"}})],1):_c('span',[_c('div',[_c('b-form-input',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive.hover",value:(
            'Puntaje máximo: ' + _vm.test.max_score
          ),expression:"\n            'Puntaje máximo: ' + test.max_score\n          ",modifiers:{"v-secondary":true,"noninteractive":true,"hover":true}}],ref:'student:' +
            _vm.student.id +
            'evaluation:' +
            _vm.evaluation.id +
            'test:' +
            _vm.test.id,staticClass:"m-0 p-0 w-100 text-center",attrs:{"size":"sm","min":0,"type":"number","value":_vm.getStudentScore.final_score != null
              ? parseFloat(_vm.getStudentScore.final_score.toFixed(2))
              : _vm.getStudentScore.manual_score != null
              ? parseFloat(_vm.getStudentScore.manual_score.toFixed(2))
              : _vm.getStudentScore.automated_score != null
              ? parseFloat(_vm.getStudentScore.automated_score.toFixed(2))
              : 0}}),_c('b-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive.hover.top",value:('Guardar'),expression:"'Guardar'",modifiers:{"v-secondary":true,"noninteractive":true,"hover":true,"top":true}}],staticClass:"ml-1 mt-2 style-icon-btn",attrs:{"icon":"save2"},on:{"click":function($event){return _vm.patchStudentScore()}}}),_c('b-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive.hover",value:(
            'Utilizar Puntaje automático: ' +
            +(
              Math.round(Number(_vm.getStudentScore.automated_score) + 'e+2') +
              'e-2'
            )
          ),expression:"\n            'Utilizar Puntaje automático: ' +\n            +(\n              Math.round(Number(getStudentScore.automated_score) + 'e+2') +\n              'e-2'\n            )\n          ",modifiers:{"v-secondary":true,"noninteractive":true,"hover":true}}],staticClass:"ml-1 mt-2 style-icon-btn",attrs:{"icon":"arrow-repeat"},on:{"click":function($event){return _vm.patchAutomateScore()}}})],1)])]):(_vm.context == 'evaluation')?_c('div',[(!_vm.allows_crud)?_c('span',[_vm._v(" "+_vm._s(_vm.getStudentScore.final_grade != null ? parseFloat(_vm.getStudentScore.final_grade.toFixed(2)) : _vm.getStudentScore.manual_grade != null ? parseFloat(_vm.getStudentScore.manual_grade.toFixed(2)) : _vm.getStudentScore.automated_grade != null ? parseFloat(_vm.getStudentScore.automated_grade.toFixed(2)) : _vm.institution.min_grade)+" "),(
          _vm.getStudentScore.final_grade == null &&
          _vm.getStudentScore.manual_grade == null &&
          _vm.getStudentScore.automated_grade != null
        )?_c('b-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive.hover",value:('Nota automática'),expression:"'Nota automática'",modifiers:{"v-secondary":true,"noninteractive":true,"hover":true}}],staticClass:"ml-1 mt-1",attrs:{"icon":"lightbulb"}}):_vm._e()],1):_c('span',[_c('div',[_c('b-form-input',{ref:'student:' + _vm.student.id + 'evaluation:' + _vm.evaluation.id,staticClass:"m-0 p-0 w-100 text-center",attrs:{"size":"sm","step":"0.01","max":_vm.institution.max_grade,"min":_vm.institution.min_grade,"type":"number","value":_vm.getStudentScore.final_grade != null
              ? parseFloat(_vm.getStudentScore.final_grade.toFixed(2))
              : _vm.getStudentScore.manual_grade != null
              ? parseFloat(_vm.getStudentScore.manual_grade.toFixed(2))
              : _vm.getStudentScore.automated_grade != null
              ? parseFloat(_vm.getStudentScore.automated_grade.toFixed(2))
              : _vm.institution.min_grade}}),_c('b-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive.hover.top",value:('Guardar'),expression:"'Guardar'",modifiers:{"v-secondary":true,"noninteractive":true,"hover":true,"top":true}}],staticClass:"ml-1 mt-2 style-icon-btn",attrs:{"icon":"save2"},on:{"click":function($event){return _vm.patchStudentScore()}}}),_c('b-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive.hover",value:(
            'Utilizar Puntaje automático: ' +
            +(
              Math.round(Number(_vm.getStudentScore.automated_grade) + 'e+2') +
              'e-2'
            )
          ),expression:"\n            'Utilizar Puntaje automático: ' +\n            +(\n              Math.round(Number(getStudentScore.automated_grade) + 'e+2') +\n              'e-2'\n            )\n          ",modifiers:{"v-secondary":true,"noninteractive":true,"hover":true}}],staticClass:"ml-1 mt-2 style-icon-btn",attrs:{"icon":"arrow-repeat"},on:{"click":function($event){return _vm.patchAutomatedGrade()}}})],1)])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }